import { useMemo } from 'react';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { destroyCookie, parseCookies } from 'nookies';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Role } from '@agentero/models/agent';
import { RequestStatusError } from '@agentero/service-errors';
import { isEmail } from '@agentero/utils';

import { useAgentUniqueIdentifier } from 'packages/contexts/AgentUniqueIdentifierContext';
import { prefetchAgent } from 'packages/services/fetch/back-ag/useAgentResource';
import { signInPathRedirection } from 'packages/utilities/signInPathRedirection';
import { updatePasswordRoute } from 'routes';

export enum SignInStatus {
	NoError = 'NoError',
	NoActivated = 'NoActivated',
	WrongUser = 'WrongUser'
}

export type SignInFormValues = {
	email: string;
	password: string;
	status: SignInStatus;
};

const initialFormState: SignInFormValues = {
	email: '',
	password: '',
	status: SignInStatus.NoError
};

export const useSignInForm = () => {
	const { t } = useTranslation('signIn');
	const router = useRouter();
	const { agentUniqueIdentifier } = useAgentUniqueIdentifier();

	const schema = useMemo(
		() =>
			yup.object().shape({
				email: yup
					.string()
					.required(t('form.errors.required'))
					.matches(isEmail, t('form.errors.email')),
				password: yup.string().required(t('form.errors.required'))
			}),
		[]
	);

	const signInForm = useForm<SignInFormValues>({
		defaultValues: initialFormState,
		resolver: yupResolver(schema)
	});

	const onSubmit = async (values: SignInFormValues) => {
		const { email, password } = values;

		await fetch('/api/experts/sign_in', {
			method: 'POST',
			body: JSON.stringify({ email, password })
		})
			.then(response => {
				if (!response.ok) {
					throw { statusError: response.status };
				}

				return response.json();
			})
			.then(async ({ type, token }) => {
				if (type === 'reset') {
					router.push(`${updatePasswordRoute}?token=${token}`);
					return;
				}

				const cookies = parseCookies();
				const agent = await prefetchAgent(undefined, agentUniqueIdentifier);

				if (cookies.redirectUrl) {
					if (agent.role !== Role.SuperAdmin) {
						router.push(cookies.redirectUrl);

						destroyCookie(undefined, 'redirectUrl', {
							path: '/'
						});
						return;
					}

					destroyCookie(undefined, 'redirectUrl', {
						path: '/'
					});
				}

				const redirectionRoute = signInPathRedirection(agent);
				router.push(redirectionRoute);
			})
			.catch((error: { statusError: RequestStatusError }) => {
				if (error.statusError === RequestStatusError.HttpNotFound) {
					signInForm.setValue('status', SignInStatus.WrongUser, { shouldValidate: true });
				}
				if (error.statusError === RequestStatusError.HttpForbidden) {
					signInForm.setValue('status', SignInStatus.NoActivated, { shouldValidate: true });
				}
			});
	};

	return { ...signInForm, onSubmit };
};
